import React from "react"

import "./ExternalLink.css"

const ExternalLink = ({ label, link, className }) => (
  <a
    className={`external-link ${className}`}
    href={link}
    rel="noopener noreferrer"
    target="_blank"
  >
    <span>{label}</span>
    <svg
      className="external-link__icon"
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="arcs"
    >
      <path d="M7 17l9.2-9.2M17 17V7H7" />
    </svg>
  </a>
)

export default ExternalLink
