import React from "react"
import ReactDOM from "react-dom"

import "./index.css"
import App from "./App"
import { ContentProvider, fetchContent } from "./common/content"

const reactRootContainer = "root"

async function init() {
  try {
    const content = await fetchContent("./content.json")

    ReactDOM.render(
      <ContentProvider content={content}>
        <App />
      </ContentProvider>,
      document.getElementById(reactRootContainer)
    )
  } catch (err) {
    console.error(err)
    ReactDOM.render(
      <div className="error">
        <p>App Loading Error</p>
      </div>,
      document.getElementById(reactRootContainer)
    )
  }
}

init()
