import React, { useState } from "react"

import "./Root.css"

const Root = ({ children, description, onChange }) => {
  const toggleId = description.replace(/ /g, "")
  const [active, setActive] = useState(false)

  const changeThemeAndToggle = () => {
    const toggledState = !active
    setActive(toggledState)
    onChange(toggledState)
  }

  const handleOnClick = () => {
    changeThemeAndToggle()
  }

  const handleKeypress = e => {
    if (e.code === "Enter") {
      changeThemeAndToggle()
    }
  }

  const enhancedChildren = React.Children.map(
    // can only have 1 children
    React.Children.toArray(children)[0],
    child => {
      return React.cloneElement(child, {
        active,
        toggleId,
      })
    }
  )

  return (
    <div className="toggle">
      <input
        aria-label={description}
        role="switch"
        aria-checked={active}
        onKeyPress={handleKeypress}
        type="checkbox"
        id={toggleId}
        className="toggle__input visuallyhidden"
        onClick={handleOnClick}
        checked={active}
        readOnly
      />
      {enhancedChildren}
    </div>
  )
}

export default Root
