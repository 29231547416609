import { animated, useSpring } from "@react-spring/web"

import { utils } from "."
import "./ProgressBar.css"

const ProgressBar = ({
  min = 0,
  max = 100,
  now,
  description,
  minLabel,
  maxLabel,
  withAnimation = true,
}) => {
  const { scaleX } = useSpring({
    scaleX: utils.clampNumber(0, now / 100, 1),
    withAnimation,
  })

  return (
    <div
      className="progress-bar"
      role="progressbar"
      aria-valuenow={now}
      aria-valuemin={min}
      aria-valuetext={description}
      aria-valuemax={max}
    >
      {minLabel && <span className="progress-bar__label">{minLabel}</span>}
      <span className="progress-bar__bar">
        <animated.span className="progress-bar__progress" style={{ scaleX }} />
        <span className="progress-bar__shape"></span>
      </span>
      {maxLabel && <span className="progress-bar__label">{maxLabel}</span>}
    </div>
  )
}

export default ProgressBar
