import { animated, useTransition } from "@react-spring/web"

import "./Loader.css"
import { ReactComponent as Asset } from "./../assets/loader.svg"
import useStore from "../store"
import { easings } from "../constants"

const Loader = () => {
  const activeLoading = useStore(state => state.activeLoading)

  const contentTransitions = useTransition(activeLoading.length > 0, {
    from: { opacity: 0 },
    enter: { opacity: 0.6 },
    leave: { opacity: 0 },
    config: { duration: 700, easing: easings.easeOutQuad },
  })

  return (
    <>
      {contentTransitions(
        (styles, loader) =>
          loader &&
          contentTransitions && (
            <animated.div className="loader" style={styles}>
              <Asset className="loader__icon" />
            </animated.div>
          )
      )}
    </>
  )
}

export default Loader
