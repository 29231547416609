import { animated, useSpring } from "@react-spring/web"

import "./IconLabel.css"
import { CONTENT_TOGGLE_DELAY } from "./constants"
import GenericLabel from "./GenericLabel"

const IconLabel = ({ active, toggleId, onIcon, offIcon }) => {
  const onLabelTransitions = useSpring({
    opacity: active ? 1 : 0,
    delay: !active ? 0 : CONTENT_TOGGLE_DELAY,
  })

  const offLabelTransitons = useSpring({
    opacity: active ? 0 : 1,
    delay: active ? 0 : CONTENT_TOGGLE_DELAY,
  })

  return (
    <GenericLabel toggleId={toggleId} type="icon">
      <animated.span style={onLabelTransitions} aria-hidden={!active}>
        {onIcon}
      </animated.span>
      <animated.span style={offLabelTransitons} aria-hidden={active}>
        {offIcon}
      </animated.span>
    </GenericLabel>
  )
}

export default IconLabel
