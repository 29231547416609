import { Leva } from "leva"
import React, { Suspense, useEffect, useState } from "react"
import { Globals } from "@react-spring/shared"

import useStore, {
  goToChapter,
  startExperience,
  setClientUsesTouch,
} from "./store"
import {
  About,
  Intro,
  Header,
  Loader,
  utils,
  GradientBackground,
} from "./common"

const Experience = React.lazy(() => import("./experience"))

Globals.assign({
  frameLoop: "always",
})

function App() {
  const urlParams = new URLSearchParams(window.location.search)
  const debug = urlParams.get("debug") !== null

  const inExperience = useStore(state => state.inExperience)
  const inAbout = useStore(state => state.inAbout)

  const [renderExperience, setRenderExperience] = useState(false)

  useEffect(() => {
    window.addEventListener("touchstart", setClientUsesTouch, false)

    return () =>
      window.removeEventListener("touchstart", setClientUsesTouch, false)
  })

  useEffect(() => {
    goToChapter(1)
  }, [])

  utils.useTimeout(() => setRenderExperience(true), 2000)

  return (
    <>
      {inExperience && (
        <>
          <Header />
          <About />
          <Loader />
        </>
      )}
      {!inExperience && <Intro onFinished={startExperience} />}

      <Suspense fallback={null}>{renderExperience && <Experience />}</Suspense>

      <GradientBackground shrink={!inExperience || inAbout} />
      <Leva hidden={process.env.NODE_ENV === "production" ? true : !debug} />
    </>
  )
}

export default App
