import { animated, useTransition } from "@react-spring/web"
import { button, useControls } from "leva"
import { useState } from "react"

import { easings } from "../constants"
import { useContent } from "./content"
import * as ContentStepper from "./contentStepper"
import "./Intro.css"

const Intro = ({ onFinished }) => {
  useControls({
    "skip intro": button(onFinished),
  })

  const content = useContent({ path: "intro" })
  const [show, setShow] = useState(true)

  const containerTransition = useTransition(show, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 700, easing: easings.easeOutQuad },
    onRest: () => {
      if (!show) {
        onFinished()
      }
    },
  })

  return (
    <>
      {containerTransition(
        (containerStyles, item) =>
          item && (
            <animated.div className="intro" style={containerStyles}>
              <ContentStepper.Root
                onFinishedButtonLabel={content.button}
                onFinished={() => setShow(false)}
                stepIntervals={[5000, 8000, 3300]}
              >
                {content.steps.map(({ title, subTitle, text }, index) => (
                  <ContentStepper.Step
                    key={index}
                    title={title}
                    subTitle={subTitle}
                  >
                    <p className="intro__text copy-text">{text}</p>
                  </ContentStepper.Step>
                ))}
              </ContentStepper.Root>
            </animated.div>
          )
      )}
    </>
  )
}

export default Intro
