import create from "zustand"
import { utils } from "./common"

import { allChaptersCount } from "./constants"

const useStore = create((set, get) => ({
  clientProfile: {
    usesTouch: false,
  },
  activeLoading: [],

  inAbout: false,
  inExperience: false,
  experienceFrameLoopActive: false,

  chapter: {
    index: undefined,
    images: undefined,
    captions: undefined,
  },
  chapterTransitionActive: true,
  chapterStarted: false,

  cameraMoving: true,
  cameraAtMinPos: false,
  cameraAtMaxPos: false,
  cameraProgress: 0,
}))

export default useStore

export const setClientUsesTouch = () =>
  useStore.setState(state => ({
    clientProfile: {
      ...state.clientProfile,
      usesTouch: true,
    },
  }))

export const startExperience = async () => {
  await goToChapter(1)

  return useStore.setState({
    inExperience: true,
    experienceFrameLoopActive: true,
  })
}

export const setChapterTransition = state =>
  useStore.setState({
    chapterTransitionActive: state,
  })
export const setChapterStarted = () =>
  useStore.setState({
    chapterStarted: true,
  })

export const goToChapter = async index => {
  const { modules, origins } = await utils.chapterImagesRequireContextes(index)

  return useStore.setState({
    cameraProgress: 0,
    chapterStarted: false,
    chapter: {
      index: index,
      images: modules,
      captions: origins,
    },
  })
}
export const goToNextChapter = async () => {
  const { index: currentIndex } = useStore.getState().chapter || 1
  const nextIndex =
    currentIndex + 1 <= allChaptersCount ? currentIndex + 1 : currentIndex

  await goToChapter(nextIndex)
}
export const goToPreviousChapter = async () => {
  const { index: currentIndex } = useStore.getState().chapter || 1
  const nextIndex = currentIndex - 1 >= 1 ? currentIndex - 1 : currentIndex

  await goToChapter(nextIndex)
}

export const toggleAbout = () =>
  useStore.setState(state => ({
    inAbout: !state.inAbout,
    experienceFrameLoopActive: state.inAbout,
  }))

export const setCameraPositionBounds = (isAtMin, isAtMax) =>
  useStore.setState({
    cameraAtMinPos: isAtMin,
    cameraAtMaxPos: isAtMax,
  })
export const setCameraMoving = () => useStore.setState({ cameraMoving: true })
export const setCameraProgress = cameraProgress =>
  useStore.setState({
    cameraProgress,
  })
export const setCameraHalt = () =>
  useStore.setState({
    cameraMoving: false,
  })

export const addToActiveLoading = activeLoading =>
  useStore.setState(state => ({
    activeLoading: [...state.activeLoading, activeLoading],
  }))
export const removeFromActiveLoading = notActiveLoading =>
  useStore.setState(state => ({
    activeLoading: state.activeLoading.filter(
      activeLoading => activeLoading !== notActiveLoading
    ),
  }))
