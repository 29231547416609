/* based on https://piccalil.li/tutorial/get-css-custom-property-value-with-javascript/ */
const getCSSCustomProp = (
  propKey,
  element = document.documentElement,
  castAs = "string"
) => {
  let response = getComputedStyle(element).getPropertyValue(propKey)

  if (response.length) {
    response = response.replace(/'|"/g, "").trim()
  }

  return response
}

export default getCSSCustomProp
