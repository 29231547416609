import "./Header.css"
import { toggleAbout } from "../store"
import * as Toggle from "./toggle"
import { useContent } from "./content"

import { ReactComponent as Logo } from "./../assets/logo.svg"
import { ReactComponent as OpenAbout } from "./../assets/open-about.svg"
import { ReactComponent as CloseAbout } from "./../assets/close-about.svg"

const Header = () => {
  const content = useContent({ path: "header" })

  return (
    <header className="header">
      <Logo className="header__logo" />
      <Toggle.Root
        description={content.aboutToggleDescription}
        onChange={toggleAbout}
      >
        <Toggle.IconLabel
          onIcon={<CloseAbout />}
          offIcon={<OpenAbout />}
        ></Toggle.IconLabel>
      </Toggle.Root>
    </header>
  )
}

export default Header
