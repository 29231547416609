const chapterImagesRequireContextes = async chapter => {
  const requireContextesForChapter = {
    1: require.context("./../../data/chapter1", true, /\.jpg$/, "lazy"),
    2: require.context("./../../data/chapter2", true, /\.jpg$/, "lazy"),
  }

  const contexts = requireContextesForChapter[chapter].keys()
  const requestPromises = contexts.map(filePath =>
    requireContextesForChapter[chapter](filePath)
  )
  const modules = await Promise.all(requestPromises)

  return {
    modules,
    origins: contexts,
  }
}

export default chapterImagesRequireContextes
