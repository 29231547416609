import { animated, useTransition } from "@react-spring/web"

import "./Step.css"
import { easings } from "./../../constants"
import { FADE_TIME, STEP_ELEMENT_Y_ANIMATION_VALUE } from "./constants"

const Step = ({
  children,
  title,
  titleAs = "h1",
  subTitle,
  subTitleAs = "h2",
  active,
  childrenDelay = FADE_TIME,
}) => {
  const AnimatedTitle = animated[titleAs]
  const AnimatedSubTitle = animated[subTitleAs]

  const titleTransitions = useTransition(active, {
    from: { opacity: 0, y: STEP_ELEMENT_Y_ANIMATION_VALUE },
    enter: { opacity: 1, y: 0, delay: 1500 },
    leave: { opacity: 0, y: STEP_ELEMENT_Y_ANIMATION_VALUE },
    config: { duration: FADE_TIME, easing: easings.easeOutQuad },
  })

  const subTitleTransitions = useTransition(active, {
    from: { opacity: 0, y: STEP_ELEMENT_Y_ANIMATION_VALUE },
    enter: { opacity: 1, y: 0, delay: 1200 },
    leave: { opacity: 0, y: STEP_ELEMENT_Y_ANIMATION_VALUE },
    config: { duration: FADE_TIME, easing: easings.easeOutQuad },
  })

  const contentTransitions = useTransition(children && active, {
    from: { opacity: 0, y: STEP_ELEMENT_Y_ANIMATION_VALUE },
    enter: { opacity: 1, y: 0, delay: 1500 + childrenDelay },
    leave: { opacity: 0, y: STEP_ELEMENT_Y_ANIMATION_VALUE },
    config: { duration: FADE_TIME, easing: easings.easeOutQuad },
  })

  return (
    <>
      <div className="content-stepper-step">
        <div className="content-stepper-step__headline-group">
          <div className="overflow-hidden">
            {subTitleTransitions(
              (styles, step) =>
                step && (
                  <AnimatedSubTitle
                    style={styles}
                    className="content-stepper-step__subTitle"
                  >
                    {subTitle}
                  </AnimatedSubTitle>
                )
            )}
          </div>
          <div className="overflow-hidden">
            {titleTransitions(
              (styles, step) =>
                step && (
                  <AnimatedTitle
                    style={styles}
                    className="content-stepper-step__title"
                  >
                    {title}
                  </AnimatedTitle>
                )
            )}
          </div>
        </div>
        {contentTransitions(
          (styles, content) =>
            content && (
              <div className="content-stepper-step__content overflow-hidden">
                <animated.div style={styles}>{children}</animated.div>
              </div>
            )
        )}
      </div>
    </>
  )
}

export default Step
