import "./Button.css"
import { ReactComponent as ArrowAsset } from "./../assets/arrow.svg"
import { ReactComponent as CircleAsset } from "./../assets/circle.svg"

const Button = ({ label, onClick }) => {
  return (
    <button className="button copy-text" onClick={onClick}>
      {label}
      <ArrowAsset className="button__arrow" aria-hidden />
      <CircleAsset className="button__circle" aria-hidden />
    </button>
  )
}

export default Button
