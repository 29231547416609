export const breakpoint = 1024

const isLargeScreen = window.matchMedia(`(min-width: ${breakpoint}px)`).matches
export const itemsPerSegment = isLargeScreen ? 40 : 160
export const itemsPerRow = isLargeScreen ? 4 : 2
export const itemPerColumn = isLargeScreen ? 4 : 2

export const allChaptersCount = 2
export const chapterTransitionTime = 2000

export const trackingShot = {
  enterExperience: {
    offsetToStartPos: isLargeScreen ? 144 : 64,
  },
  chapterSwitch: {
    offsetToStartPos: isLargeScreen ? 64 : 32,
  },
}

export const easings = {
  easeOutQuad: t => t * (2 - t),
}
