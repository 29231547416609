import "./GenericLabel.css"

const GenericLabel = ({ toggleId, children, type }) => {
  return (
    <label
      htmlFor={toggleId}
      className={`toggle__label toggle__label--${type}`}
    >
      {children}
    </label>
  )
}

export default GenericLabel
