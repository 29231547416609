import { useContext, useMemo } from "react"
import get from "lodash.get"
import mapValues from "lodash.mapvalues"
import isPlainObject from "lodash.isplainobject"

import { ContentContext } from "./contentProvider"

const useContent = ({ path }) => {
  const allContent = useContext(ContentContext)

  return useMemo(() => {
    const selectedContent = path
      ? get(allContent, path.replaceAll("/", "."))
      : allContent
    return getContentForPath(selectedContent)
  }, [allContent, path])
}

function getContentForPath(content) {
  if (isPlainObject(content)) {
    return mapValues(content, item => getContentForPath(item))
  }

  return content
}

export default useContent
