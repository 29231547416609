import React, { useState } from "react"
import { animated, useTransition } from "@react-spring/web"

import "./Root.css"
import { FADE_TIME, STEP_ELEMENT_Y_ANIMATION_VALUE } from "./constants"
import { ReactComponent as Logo } from "./../../assets/logo-bg.svg"
import * as utils from "../utils"
import Button from "../Button"
import { easings } from "./../../constants"

const Root = ({
  onFinished,
  onFinishedButtonLabel,
  stepIntervals,
  children,
}) => {
  const [step, setStep] = useState(0)
  const maxSteps = children.length - 1

  utils.useInterval(
    () => {
      if (step < maxSteps) {
        setStep(step + 1)
      }
    },
    step < maxSteps ? stepIntervals[step] : null
  )

  const buttonTransitions = useTransition(onFinished && step === maxSteps, {
    from: { opacity: 0, y: STEP_ELEMENT_Y_ANIMATION_VALUE },
    enter: { opacity: 1, y: 0, delay: stepIntervals[maxSteps] - FADE_TIME },
    leave: { opacity: 0, y: STEP_ELEMENT_Y_ANIMATION_VALUE },
    config: { duration: FADE_TIME, easing: easings.easeOutQuad },
  })

  const enhancedChildren = React.Children.map(children, (child, index) =>
    React.cloneElement(child, {
      active: step === index,
    })
  )

  return (
    <div className="content-stepper">
      {enhancedChildren}
      {buttonTransitions(
        (styles, visible) =>
          visible && (
            <div className="content-stepper__finish-button">
              <animated.div style={styles}>
                <Button onClick={onFinished} label={onFinishedButtonLabel} />
              </animated.div>
            </div>
          )
      )}

      <Logo className="content-stepper__bg" />
    </div>
  )
}

export default Root
