import { animated, useSpring } from "@react-spring/web"

import { easings } from "../constants"
import "./GradientBackground.css"

const GradientBackground = ({ shrink = false }) => {
  const { number } = useSpring({
    from: { number: 0 },
    to: { number: shrink ? 1 : 0 },
    config: { duration: 700, easing: easings.easeOutQuad },
  })

  return (
    <div className="gradient-background" aria-hidden>
      <animated.div
        style={{
          clipPath: number.to(
            n => `inset(clamp(${n * 16}px, ${n * 2}vw, ${n * 32}px))`
          ),
        }}
        className="gradient-background__gradient"
      ></animated.div>
    </div>
  )
}

export default GradientBackground
