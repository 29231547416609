import { animated, useSpring } from "@react-spring/web"

import "./TextLabel.css"
import { CONTENT_TOGGLE_DELAY } from "./constants"
import GenericLabel from "./GenericLabel"

const TextLabel = ({ active, toggleId, onLabel, offLabel }) => {
  const onLabelTransitions = useSpring({
    opacity: active ? 1 : 0,
    delay: !active ? 0 : CONTENT_TOGGLE_DELAY,
  })

  const offLabelTransitons = useSpring({
    opacity: active ? 0 : 1,
    delay: active ? 0 : CONTENT_TOGGLE_DELAY,
  })

  return (
    <GenericLabel toggleId={toggleId} type="text">
      <animated.span
        className="copy-text"
        style={onLabelTransitions}
        aria-hidden={!active}
      >
        {onLabel}
      </animated.span>
      <animated.span
        className="copy-text"
        style={offLabelTransitons}
        aria-hidden={active}
      >
        {offLabel}
      </animated.span>
    </GenericLabel>
  )
}

export default TextLabel
