import { animated, useTransition } from "@react-spring/web"

import "./About.css"
import { easings } from "../constants"
import useStore, { toggleAbout } from "../store"
import { useContent } from "./content"
import * as ContentStepper from "./contentStepper"
import GradientBackground from "./GradientBackground"
import ExternalLink from "./ExternalLink"

const List = ({ items }) => (
  <ul className="about-list__list">
    {items.map(({ label, link }, key) => (
      <li className="about-list__list-item" key={key}>
        {link && (
          <ExternalLink
            link={link}
            label={label}
            className="about-section__value"
          />
        )}
      </li>
    ))}
  </ul>
)

const About = () => {
  const inAbout = useStore(state => state.inAbout)
  const content = useContent({ path: "about" })

  const containerTransition = useTransition(inAbout, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { duration: 700, easing: easings.easeOutQuad },
  })

  return (
    <>
      {containerTransition(
        (containerStyles, item) =>
          item && (
            <animated.div className="about" style={containerStyles}>
              <GradientBackground shrink={inAbout} />
              <ContentStepper.Root
                onFinished={toggleAbout}
                stepIntervals={[180000]}
              >
                <ContentStepper.Step
                  childrenDelay={350}
                  title={content.title}
                  subTitle={content.subTitle}
                >
                  <div className="about__content">
                    {content.sections.map(({ listItems, label }, key) => (
                      <div key={key} className="about-section">
                        <h3 className="about-section__title">{label}</h3>
                        {listItems && <List items={listItems} />}
                      </div>
                    ))}
                  </div>
                </ContentStepper.Step>
              </ContentStepper.Root>
            </animated.div>
          )
      )}
    </>
  )
}

export default About
